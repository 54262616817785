<template>
  <div class="verify">
    <Nav navType="fun_text" textCon="填写实名认证信息">
      <template v-slot:rightText>
        <span
          class="iconfont icongengduo"
          style="color:#000;font-size:24px"
        ></span>
      </template>
    </Nav>
    <div class="title_text">请填写您的身份信息</div>
    <!-- 实名认证 -->
    <div class="real_name">
      <div class="form_item">
        <div class="form_label">姓名</div>
        <Field v-model="realForm.name" placeholder="请输入您的姓名" class="form_value" />
      </div>
      <div class="form_item">
        <div class="form_label">性别</div>
        <div class="form_value">
          <checkbox v-model="sex1" checked-color="#ff8000" class="form_check" @change="selectSex1">男</checkbox>
          <checkbox v-model="sex2" checked-color="#ff8000" class="form_check" @change="selectSex2">女</checkbox>
        </div>
      </div>
      <div class="form_item">
        <div class="form_label">身份证号</div>
        <Field v-model="realForm.idcard" placeholder="请输入您的身份证号码" class="form_value" />
      </div>
      <div class="form_item">
        <div class="form_label">签发机关</div>
        <Field v-model="realForm.sign_org" placeholder="请输入您身份证的签发机关" class="form_value" />
      </div>
      <div class="form_image">
        <uploader :after-read="afterRead1" v-model="image1" :max-count="1" class="form_image_item" preview-size="150px" upload-text="请添加身份证正面照片"/>
        <uploader :after-read="afterRead2" v-model="image2" :max-count="1" class="form_image_item" preview-size="150px" upload-text="请添加身份证反面照片"/>
        <uploader :after-read="afterRead3" v-model="image3" :max-count="1" class="form_image_item" preview-size="150px" upload-text="请添加手持身份证照片"/>
        <uploader :after-read="afterRead4" v-model="image4" :max-count="1" class="form_image_item" preview-size="150px" upload-text="请添加个人自拍照片"/>
      </div>
      <div class="form_info">
        <div class="info_title">拍摄上传身份证注意事项</div>
        <p>1.大陆公民持有的本人有效二代身份证</p>
        <p>2.拍摄上传时确保身份证<span>边框工整、字迹清晰、亮度均匀</span></p>
        <div class="info_image">
          <div class="image_box">
            <div class="image">
              <Icon name="checked" class="image_icon" color="#FF8000"/>
            </div>
            <div class="image_text">标准</div>
          </div>
          <div class="image_box">
            <div class="image">
              <Icon name="clear" class="image_icon" color="#E9434C"/>
            </div>
            <div class="image_text">边框缺失</div>
          </div>
          <div class="image_box">
            <div class="image">
              <Icon name="clear" class="image_icon" color="#E9434C"/>
            </div>
            <div class="image_text">字迹模糊</div>
          </div>
          <div class="image_box">
            <div class="image">
              <Icon name="clear" class="image_icon" color="#E9434C"/>
            </div>
            <div class="image_text">闪光强烈</div>
          </div>
        </div>
      </div>
    </div>
    <div class="real_name_button">
      <div class="button" style="height:37px;line-height:37px;" @click="upimgPort">提交审核</div>
    </div>
    <!-- 成功弹窗 -->
    <Popup v-model="show_success" class="success">
      <div class="image"></div>
      <div class="success_text">提交成功</div>
      <div class="success_info">您的实名认证信息已提交</div>
      <div class="success_info">预计3个工作日审核完成，请耐心等待</div>
      <div class="success_button" @click="closePage">我知道了</div>
    </Popup>
  </div>
</template>
<script>
import Nav from "@components/public/searchTop";
import { Field, Popup, Checkbox, Uploader, Icon } from "vant" 
import Setting from "@/api/setting"
export default {
  data() {
    return {
      sex1:true,
      sex2:false,
      realForm:{}, //实名认证表单
      image1:[], //身份证正面
      image2:[], //身份证背面
      image3:[], //手持身份证
      image4:[], //自拍
      showKeyboard: false,
      show_success:false
    };
  },
  mounted(){
    this.init()
  },
  methods: {
    init(){},
    selectSex1(event){
      this.sex2 = !event;
    },  
    selectSex2(event){
      this.sex1 = !event;
    }, 
    afterRead1(){
      console.log(1)
    },
    afterRead2(){
      console.log(2)
    },
    afterRead3(){
      console.log(3)
    },
    afterRead4(){
      console.log(4)
    },
    // 上传图片到服务器
    async upimgPort() {
      try {
        const res0 = await Setting.upImg(this.image1[0].file, 5);
        const res1 = await Setting.upImg(this.image2[0].file, 5);
        const res2 = await Setting.upImg(this.image3[0].file, 5);
        const res3 = await Setting.upImg(this.image4[0].file, 5);
        this.realForm.front = res0.data.id
        this.realForm.back = res1.data.id
        this.realForm.hand = res2.data.id
        this.realForm.photo = res3.data.id
        this.upData()
      } catch (err) {
        console.log(err);
      }
    },
    // 提交表单
    async upData() {
      this.realForm.sex = this.sex1? 1:2;
      var data = Object.assign({},this.realForm)
      try {
        const res = await Setting.SetCert(data);
        if(res.code == 200){
          this.show_success = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
    closePage(){
      this.show_success = false;
    },
  },
  components: {
    Nav,
    Field,
    Popup,
    Checkbox,
    Uploader,
    Icon
  }
};
</script>
<style>
.van-uploader__wrapper{
  height: 100%
}
.van-password-input{
  margin: 0;
}
</style>
<style lang="scss" scoped>
.verify {
  width: 100%;
  min-height: 100%;
  padding: 50px 12px 1px 12px;
  background-color: fff;
  font-family: PingFang SC;
  background-color: #f4f4f4;
  font-weight: 500;
  position: relative;
  .title_text {
    height: 63px;
    line-height: 63px;
    font-size: 15px;
    color: #333;
  }
  .button{
    background:linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
    border-radius: 5px;
    text-align: center;
    color:#fff;
    font-size: 15px;
    height: 45px;
    line-height: 45px;
  }
  .real_name_button{
    position: fixed;
    bottom: 0;
    left: 0;
    width:100%;
    height: 52px;
    padding: 7px 12px;
    background-color: #fff;
  }
  .real_name{
    border-radius: 5px;
    background-color: #fff;
    font-size: 14px;
    padding: 0 10px;
    margin-bottom: 60px;
    padding-bottom: 17px;
    .form_item{
      height: 45px;
      line-height: 45px;
      border-bottom: 1px solid #f4f4f4;
      display: flex;
      .form_label{
        width:78px;
        color:#333;
      }
      .form_value{
        flex:1;
        color:#999;
        padding:0;
        height: 100%;
        line-height: 45px;
        display: flex;
        .form_check{
         margin-right: 35px;
        }
      }
    }
    .form_image{
      padding-top: 17px;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #f4f4f4;
      .form_image_item{
        vertical-align:top;
        flex:1;
        height: 150px;
        margin-bottom: 17px;
      }
    }
    .form_info{
      padding-top: 17px;
      color:#333;
      .info_title{
        margin-bottom: 10px;
        font-size: 14px;
      }
      p{
        line-height: 18px;
        font-size: 13px;
        color:#333;
        span{
          color: #FF8000;
        }
      }
      .info_image{
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        .image_box{
          flex:1;
          margin:0 3px;
          text-align: center;
          color:#999;
          font-size: 13px;
          width:76px;
          .image{
            height: 50px;
            border: 1px solid #f4f4f4;
            position: relative;
            .image_icon{
              position: absolute;
              width: 14px;
              height: 14px;
              bottom: -7px;
              left: 31px;
            }
          }
          .image_text{
            margin-top: 16px;
          }
        }
      }
    }
  }
  .success{
    width:66%;
    padding:0 13px;
    text-align: center;
    .image{
      margin: 0 auto;
      margin-top: 35px;
      width:84px;
      height: 84px;
      border:1px solid #eee;
    }
    .success_text{
      margin-top: 20px;
      margin-bottom: 15px;
      font-size: 16px;
    }
    .success_info{
      font-size: 13px;
      line-height: 17px;
    }
    .success_button{
      margin:14px auto 24px auto;
      width:138px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      font-size: 13px;
      color:#fff;
      background:linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
    }
  }
}
</style>
